import * as React from 'react';
import * as styles from './terms.module.scss';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Button from '../components/button';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Section from '../components/section';

const PrivacyPage = () => {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="Learn about Interact's privacy practices, including how we collect, use, and protect your personal information when you use our quiz creation services and website."
      />
      <Header />
      <main className={styles.terms}>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Privacy Policy</h1>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ paddingTop: '0' }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <p className="text-body color-black">
                <strong>Last Updated: September 4th, 2024</strong>
              </p>
              <p className="text-body color-black">
                The Quiz Collective, Inc. (doing business as Interact) (“Interact”, “<em>we</em>” or
                “<em>us</em>”) provides tools for creating online quizzes to help grow businesses.
                This Privacy Policy applies to personal information we collect from you or about
                you, including when you use our website located at www.tryinteract.com (the “
                <em>Site</em>”) or use our services that link to this Privacy Policy (collectively,
                our “<em>Services</em>”), contact our customer service team, engage with us on
                social media, or otherwise interact with us. This Privacy Policy applies to
                information collected online through or in connection with our Services. Unless
                otherwise stated below, this Privacy Policy does not apply to offline collection of
                information or activities outside of our Services.
              </p>
              <p className="text-body color-black">
                To better understand what information is most relevant to you, we have provided some
                useful definitions.
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>
                  <strong>Client.</strong> You hold an account with us to create and conduct
                  quizzes, polls, or promotions using our Services.
                </li>
                <li>
                  <strong>Website Visitor.</strong> You are visiting our Site but have not signed up
                  for an account with us or otherwise interacted with Interact.
                </li>
                <li>
                  <strong>Respondents.</strong> You have received or participated in an online Quiz,
                  poll, or promotion from us on behalf of one of our Clients or, in some instances,
                  on our own behalf.
                </li>
                <li>
                  <strong>Quiz.</strong> “Quiz” refers to an online quiz, poll, or promotion created
                  by a Client using Interact Services. In some limited instances, Interact may make
                  available and administer a Quiz on its own behalf. When we do so the Quiz page
                  will so indicate or include a link with additional information.
                </li>
              </ul>
              <p className="text-body color-black">
                The personal information we collect and how we use it may differ if you are a
                Client, Respondent or Website Visitor so we make these distinctions in this Privacy
                Policy, where applicable.
              </p>
              <p className="text-body color-black">
                We may change this Privacy Policy from time to time. If we make changes, we will
                notify you by posting a revised version of the policy to the Services with the
                updated revision date and, in some cases, we may provide you with additional notice
                (such as adding a statement to our website homepage or sending you a notification).
                If the changes are material, we will provide notice of the changes on the Services
                prior to the changes becoming effective. We encourage you to review this Privacy
                Policy regularly to stay informed about our information practices and the choices
                available to you.
              </p>
              <p className="text-body color-black">
                Additionally, if you are a California resident, you may have additional rights under
                California law. Therefore, please review section 7. If you reside in the EU, you may
                have additional rights under EU law and can refer to section 8.
              </p>

              <p className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                Contents
              </p>
              <AnchorLink
                to="/privacy/#collection-of-information"
                title="Collection of Information"
                className="text-body"
              />
              <br />
              <AnchorLink
                to="/privacy/#use-of-information"
                title="Use of Information"
                className="text-body"
              />
              <br />
              <AnchorLink
                to="/privacy/#sharing-of-information"
                title="Sharing of Information"
                className="text-body"
              />
              <br />
              <AnchorLink
                to="/privacy/#advertising-and-analytics"
                title="Advertising and Analytics"
                className="text-body"
              />
              <br />
              <AnchorLink
                to="/privacy/#use-of-our-services-outside-us"
                title="Use of Our Services Outside U.S."
                className="text-body"
              />
              <br />
              <AnchorLink to="/privacy/#your-choices" title="Your Choices" className="text-body" />
              <br />
              <AnchorLink
                to="/privacy/#your-california-privacy-rights"
                title="Your California Privacy Rights"
                className="text-body"
              />
              <br />
              <AnchorLink
                to="/privacy/#additional-disclosures-for-individuals-in-europe"
                title="Additional Disclosures for Individuals in Europe"
                className="text-body"
              />
              <br />
              <AnchorLink to="/privacy/#contact-us" title="Contact Us" className="text-body" />
              <br />

              <p
                id="collection-of-information"
                className="heading-md color-black"
                style={{ margin: `1.5rem 0` }}>
                1. Collection of Information
              </p>
              <p className="text-body color-black">
                The types of personal information we may collect and how we collect it may vary
                based on your relationship with us. For purposes of this Privacy Policy, “personal
                information” means information that identifies, relates to, describes, is reasonably
                capable of being associated with, or could reasonably be linked, directly or
                indirectly, with a particular individual or (where required by applicable law)
                household. Personal information does not include publicly available information or
                information that has been de-identified, as defined by applicable laws.
              </p>
              <p className="heading-sm color-black">
                <strong>Information You Provide Us</strong>
              </p>
              <p className="text-body color-black">
                We collect information you provide directly to us, which may include contact
                information, like your name or email address, and any other information you choose
                to provide. For example, you share information directly with us when you subscribe
                to our newsletter or sign up for our email updates, fill out a webform on our Site,
                respond to one of our Client’s Quizzes or Interact’s own Quizzes, request customer
                support, or when you otherwise communicate with us (e.g., chat).
              </p>
              <p className="heading-sm color-black">
                <strong>Other Information We Collect When You Use Our Services</strong>
              </p>
              <p className="text-body color-black">
                When you access or use our Services, we automatically collect information about your
                activities, including:
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>
                  <strong>Device Data:</strong> We collect information about how you access our
                  Services, including data about the device and network you use, such as your
                  hardware model, operating system version, mobile network, IP address, unique
                  device identifiers and browser type.
                </li>
                <li>
                  <strong>Log and Usage Data:</strong> Log and usage data is service-related,
                  diagnostic, usage, and performance information our servers automatically collect
                  when you access or use our Services and which we record in log files. Depending on
                  how you interact with our Services, this log data may include your IP address,
                  device information, browser type, and settings and information about your activity
                  on our Services (such as the date/time stamps associated with your usage, pages
                  and files viewed, links clicked, searches, and other actions you take such as
                  which features you use), device event information (such as system activity, error
                  reports, and hardware settings).
                </li>
                <li>
                  <strong>Location Data: </strong> We collect location data such as information
                  about your device’s location, which can be either precise or imprecise. How much
                  information we collect depends on the type and settings of the device you use to
                  access our Services. You can opt out of this by disabling your Location settings
                  on your computer. However, if you choose to opt out, you may not be able to use
                  certain aspects of our Services.
                </li>
                <li>
                  <strong>
                    Information Collected by Cookies and Similar Tracking Technologies:
                  </strong>{' '}
                  We (and our service providers) use tracking technologies, such as cookies and web
                  beacons, to collect information about you. Cookies are small data files stored on
                  your hard drive or in device memory that help us improve our Services and your
                  experience, see which areas and features of our Services are popular, and count
                  visits. Web beacons (also known as “pixel tags” or “clear GIFs”) are electronic
                  images that may be used in our Services or emails and help deliver cookies, count
                  visits, and understand usage and campaign effectiveness. For more information
                  about cookies and how to disable them, see our{' '}
                  <Link to="/cookies/">Cookie Policy</Link> and the{' '}
                  <AnchorLink
                    to="/privacy/#your-choices"
                    title="Your Choices"
                    className="color-pink"
                  />{' '}
                  section below.
                </li>
              </ul>
              <p className="heading-sm color-black">
                <strong>Information We Collect from Other Sources</strong>
              </p>
              <p className="text-body color-black">
                We may collect information about you from third parties. For example, we may collect
                information from advertising networks and data analytics providers and other third
                parties to whom you give permission to share your information with us or where you
                have made that information publicly available online. Our Clients may provide us
                with information about you to manage our relationship with the Client or facilitate
                Quizzes on their behalf.
              </p>
              <p className="heading-sm color-black">
                <strong>Information We Derive</strong>
              </p>
              <p className="text-body color-black">
                We may derive information or draw inferences about you based on the information we
                or our partners collect. For example, we may make inferences about your location
                based on your IP address or infer that you are looking to purchase certain products
                based on your browsing behavior.
              </p>
              <p className="heading-sm color-black">
                <strong>Respondents Information</strong>
              </p>
              <p className="text-body color-black">
                When we manage a Quiz on behalf of one of our Clients, the information Respondents
                provide in completing the Quiz (“Quiz Information”) is controlled by the Client. In
                those instances, Quiz Information is governed by the privacy practices of the
                Client, not this policy. Therefore, please review the applicable Client’s privacy
                policy or notice outlining how your information will be collected, used, stored,
                used, and managed.
              </p>
              <p className="text-body color-black">
                There may be instances where we create our own Quizzes on our own behalf. If you
                complete one of Interact’s own Quizzes, your responses and all such Quiz Information
                will be subject to this Privacy Policy.
              </p>
              <p className="heading-sm color-black">
                <strong>Additional Information We May Collect If You Are a Client</strong>
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>
                  <strong>Registration Information:</strong> You need an Interact account before you
                  can use our Services. When you register for an account, we collect your contact
                  details and account credentials, which may include your first and last name,
                  company name, work email address, and account password or token-based
                  authentication information.
                </li>
                <li>
                  <strong>Billing Information:</strong> If you make a payment to Interact, we
                  require you to provide your billing details, which includes your name, address,
                  email address and financial information corresponding to your selected method of
                  payment (e.g., a credit card number and expiration date or a bank account number).
                  All payment data is stored by Stripe. We do not store payment data. You may find
                  their privacy notice here:{' '}
                  <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
                </li>
                <li>
                  <strong>Transactional Information:</strong> When you sign up for an Interact
                  account, purchase one of our plans or cancel your account or plan, we collect
                  information about the transaction, such as plan details, purchase price, and date
                  of the transaction.
                </li>
                <li>
                  <strong>Quiz Information:</strong> We collect your Respondents’ Quiz Information
                  (questions and responses) for you and provide analytics tools for you to use with
                  respect to this information.
                </li>
              </ul>
              <p className="heading-sm color-black">
                <strong>Links</strong>
              </p>
              <p className="text-body color-black">
                Our Site may contain links to third-party websites, including those of our business
                partners. Those websites may have their own privacy policies or no privacy policies
                at all. Interact is not responsible for the privacy practices employed by
                third-party websites, and those websites have not agreed to this privacy policy.
                Interact has no control over or responsibility for any unaffiliated third-party
                websites or the content contained in them, and we provide these links solely for
                your convenience when you visit our Site or use our Services. We do not sponsor,
                endorse, or otherwise recommend such websites or any products or services they may
                offer. In short, once you leave our Service, we are no longer responsible for the
                collection practices of the companies operating those websites.
              </p>

              <p className="heading-sm color-black">
                <strong>Social Login Information</strong>
              </p>
              <p className="text-body color-black">
                Our Services may offer you the ability to register and log in using your third-party
                social media account details (Like your Google, Facebook/Meta, or Twitter/X logins).
                Where you choose to do this, we will receive certain profile information about you
                from your social media provider. The profile information we receive may vary
                depending on the social media provider concerned, but will often include your name,
                email address, and profile picture, as well as potentially other information you
                choose to make public on said social media platform.
              </p>
              <p className="text-body color-black">
                We will use the information we receive only for the purposes that are described in
                this privacy notice or that are otherwise made clear to you on the relevant
                Services. Please note that we do not control, and are not responsible for, other
                uses of your personal information by your third-party social media provider. We
                recommend that you review their privacy notice to understand how they collect, use,
                and share your personal information, and how you can set your privacy preferences on
                their sites and apps.
              </p>

              <p
                id="use-of-information"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                2. Use of Information
              </p>
              <p className="text-body color-black">
                We use the personal information we collect from Clients to:
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>Facilitate Quizzes on our Clients’ behalf;</li>
                <li>Provide, maintain and improve our Quizzes and other products and services;</li>
                <li>
                  Process transactions, subscriptions, and send related information, including
                  confirmations, receipts, invoices, customers experience surveys, and
                  Service-related notices;
                </li>
                <li>
                  Send technical notices, security alerts, and support and administrative messages
                  and to respond to your comments, questions, and to provide you customer service;
                </li>
                <li>
                  Communicate about products, services, and events offered by Interact and others,
                  and provide news and information we think will interest to you (see{' '}
                  <AnchorLink
                    to="/privacy/#your-choices"
                    title="Your Choices"
                    className="color-pink"
                  />{' '}
                  below for information about how to opt out of these communications at any time);
                </li>
                <li>
                  Monitor and analyze trends, usage, and activities in connection with our Services;
                </li>
                <li>Tailor your experience on our Services;</li>
                <li>
                  Personalize the advertisements you see on third-party platforms and websites (for
                  more information, see the{' '}
                  <AnchorLink
                    to="/privacy/#advertising-and-analytics"
                    title="Advertising and Analytics"
                    className="color-pink"
                  />{' '}
                  section below);
                </li>
                <li>
                  Detect, investigate and prevent security incidents and other malicious, deceptive,
                  fraudulent, or illegal activity, and protect the rights and property of Interact,
                  our Clients and others;
                </li>
                <li>Debug to identify and repair errors in our Services;</li>
                <li>Comply with our legal and financial obligations; and</li>
                <li>
                  Carry out any other purpose described to you at the time the information was
                  collected.
                </li>
              </ul>
              <p className="text-body color-black" style={{ marginBottom: `1.5rem` }}>
                We use the information we collect from Website Visitors to:
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>Provide, maintain and improve our products and services;</li>
                <li>
                  Monitor and analyze trends, usage, and activities in connection with our Services;
                </li>
                <li>Tailor your experience on our Services;</li>
                <li>
                  Personalize the advertisements you see on third-party platforms and websites (for
                  more information, see the{' '}
                  <AnchorLink
                    to="/privacy/#advertising-and-analytics"
                    title="Advertising and Analytics"
                    className="color-pink"
                  />{' '}
                  section below);
                </li>
                <li>
                  Detect, investigate and prevent security incidents and other malicious, deceptive,
                  fraudulent, or illegal activity, and protect the rights and property of Interact,
                  our Clients and others;
                </li>
                <li>Debug to identify and repair errors in our Services;</li>
                <li>Comply with our legal and financial obligations; and</li>
                <li>
                  Carry out any other purpose described to you at the time the information was
                  collected.
                </li>
              </ul>
              <p className="text-body color-black" style={{ marginBottom: `1.5rem` }}>
                We use the personal information we collect from or about Respondents to:
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>Facilitate Quizzes on behalf of one of our Clients;</li>
                <li>Administer Quizzes, in some instances, on our own behalf;</li>
                <li>Provide, maintain and improve our products and services;</li>
                <li>
                  Monitor and analyze trends, usage, and activities in connection with our Services;
                </li>
                <li>
                  Detect, investigate and prevent security incidents and other malicious, deceptive,
                  fraudulent, or illegal activity, and protect the rights and property of Interact,
                  our Clients and others;
                </li>
                <li>Debug to identify and repair errors in our Services;</li>
                <li>Comply with our legal and financial obligations; and</li>
                <li>
                  Carry out any other purpose described to you at the time the information was
                  collected.
                </li>
                <li>
                  Respond to user inquiries/offer support to users. We may process your information
                  to respond to your inquiries and solve any potential issues you might have with
                  the requested service.
                </li>
                <li>
                  Send administrative information to you. We may process your information to send
                  you details about our products and services, changes to our terms and policies,
                  and other similar information.
                </li>
                <li>
                  Enable user-to-user communications. We may process your information if you choose
                  to use any of our offerings that allow for communication with another user.
                </li>
                <li>
                  Save or protect an individual’s vital interest. We may process your information
                  when necessary to save or protect an individual’s vital interest, such as to
                  prevent harm.
                </li>
              </ul>

              <p
                id="sharing-of-information"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                3. Sharing of Information
              </p>
              <p className="text-body color-black">
                We share personal information in the following circumstances or as otherwise
                described in this policy:
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>
                  With our Clients with respect to Respondent Quiz Information relating to the
                  applicable Client’s Quizzes;
                </li>
                <li>
                  With vendors, service providers, and consultants that need access to personal
                  information in order to perform services for us, such as companies that assist us
                  with web hosting, payment processing, fraud prevention, customer service, and
                  marketing and advertising;
                </li>
                <li>
                  If we believe disclosure is in accordance with, or required by, any applicable law
                  or legal process, including lawful requests by public authorities to meet national
                  security or law enforcement requirements;
                </li>
                <li>
                  If we believe your use of our Services is unlawful or inconsistent with our Terms
                  of Use, this policy or other applicable policies, or it is necessary to protect
                  the rights, property, and safety of Interact, our users, the public, or others;
                </li>
                <li>
                  With our lawyers and other professional advisors to obtain advice or otherwise
                  protect and manage our business interests;
                </li>
                <li>
                  In connection with transactions involving our business, we may transfer some or
                  all personal information in our possession to a successor organization in the
                  event of (or negotiation of) a merger, acquisition, bankruptcy or other sale or
                  transfer of all or a portion of our assets;
                </li>
                <li>
                  Between and among Interact and our current and future parents, affiliates,
                  subsidiaries, and other companies under common control and ownership; and
                </li>
                <li>With the consent or at the direction of Respondents of our own Quizzes.</li>
              </ul>
              <p className="heading-sm color-black">
                <strong>Sharing of Information from Respondents</strong>
              </p>
              <p className="text-body color-black">
                Respondents’ Quiz responses and any other Quiz Information are owned and managed by
                our Client who generated the specific Quiz. Interact processes your personal
                information on behalf of its Client and treats Quiz Information as private to the
                Client, except if the Client has made the questions and responses available via a
                public link or otherwise authorizes disclosure to other third parties. Please
                contact the Client directly for more information about the Client’s use of Quiz
                Information and their privacy practices generally.
              </p>
              <p className="text-body color-black">
                Interact does not sell personal information related to Quiz responses or other Quiz
                Information. Additionally, Interact does not use Respondent contact details
                collected in our Client’s Quizzes to contact Respondents, without the Client’s
                authorization.
              </p>

              <p
                id="advertising-and-analytics"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                4. Advertising and Analytics
              </p>
              <p className="text-body color-black">
                We allow others to provide analytics services and serve advertisements on our behalf
                across the web and in mobile apps. These entities may use cookies, web beacons,
                device identifiers and other technologies to collect information about your use of
                our Services and other websites and applications, including your IP address, web
                browser, mobile network information, pages viewed, time spent on pages or in mobile
                apps, links clicked, and conversion information. This information may be used by
                Interact and others to, among other things, analyze and track data, determine the
                popularity of certain content, deliver advertising and content targeted to your
                interests on our Services and other websites, and better understand your online
                activity. For more information about interest-based ads, or to opt out of having
                your web browsing information used for behavioral advertising purposes, please visit{' '}
                <a
                  href="https://www.aboutads.info/choices"
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  www.aboutads.info/choices
                </a>
                .
              </p>
              <p className="text-body color-black">
                We reserve the right to work with third parties to serve ads to you as part of
                customized campaigns on third-party platforms (such as Facebook and Twitter). As
                part of these ad campaigns, we or third-party platforms may convert information
                about you, such as your email address and phone number, into a unique value that can
                be matched with a user account on these platforms to allow us to learn about your
                interests and serve you advertising that is customized to your interest. Note that
                the third-party platforms may offer you choices about whether you see these types of
                customized ads.
              </p>

              <p
                id="use-of-our-services-outside-us"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                5. Use of Our Services Outside U.S.
              </p>
              <p className="text-body color-black">
                Interact is headquartered in the United States, and we have operations and service
                providers in the United States. Therefore, if you are visiting the Site or using our
                Services from a location outside of the United States (“U.S.”), we and those that
                perform work for us may transfer your personal information to, or store or access it
                in, jurisdictions that may not provide levels of data protection that are equivalent
                to those of your home jurisdiction.. All information you receive from the Site will
                be created, and all information you provide will be maintained, on servers and
                systems located within the U.S.. Where required by law, we provide adequate
                protection for the transfer of personal information in accordance with applicable
                law.
              </p>
              <p className="text-body color-black">
                By using our Services or providing us with any information, you consent to the
                transfer to, and processing, use, sharing and storage of such information in the
                U.S., as may be set forth in this Privacy Policy.
              </p>
              <p className="text-body color-black">
                If you are a located in the E.U., European Economic Area (“EEA”) United Kingdom or
                Switzerland, please see section 8 for more information about the transfer of your
                information to the U.S.
              </p>

              <p
                id="your-choices"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                6. Your Choices
              </p>
              <p className="heading-sm color-black">
                <strong>Account Information</strong>
              </p>
              <p className="text-body color-black">
                If you are a Client or other individual with whom we have a direct business
                relationship, you may update and correct certain account information you provide to
                us at any time by logging into your account or emailing us at help@tryinteract.com.
              </p>
              <p className="text-body color-black">
                If you wish to delete your account, please do so by logging in to your Interact
                account by visiting{' '}
                <a href="https://dashboard.tryinteract.com/login">
                  https://dashboard.tryinteract.com/login
                </a>{' '}
                and navigating to “Privacy” located under “Account Settings” section of the user
                dashboard or by contact us via email at privacy@tryinteract.com but note that we may
                retain certain information as required by law or for legitimate business purposes.
                We may also retain cached or archived copies of information about you.
              </p>
              <p className="heading-sm color-black">
                <strong>Cookies</strong>
              </p>
              <p className="text-body color-black">
                Most web browsers are set to accept cookies by default. If you prefer, you can
                usually adjust your browser settings to remove or reject browser cookies. Please
                note that removing or rejecting cookies could affect the availability and
                functionality of our Services. For more information about cookies and how to disable
                them, see our <Link to="/cookies/">Cookie Policy</Link>.
              </p>
              <p className="heading-sm color-black">
                <strong>Do-Not-Track Signals</strong>
              </p>
              <p className="text-body color-black">
                Because the do-not track signal does not often reflect the preference of our users,
                our Site and other Services do not respond to it. You may, however, elect not to
                receive marketing messages and/or not have certain cookies placed on your browser,
                as discussed in this Privacy Policy and our{' '}
                <Link to="/cookies/">Cookie Policy</Link>.
              </p>
              <p className="heading-sm color-black">
                <strong>Children’s Access</strong>
              </p>
              <p className="text-body color-black">
                Our Site is a general audience website intended for adult use only. Accordingly,
                none of our Services, including this Site, are directed to children. Interact does
                not market to, nor do we use the Services to knowingly collect any personal
                information from, children under the age of thirteen (13). If you are a child under
                13 years of age, you are not permitted to use the Service and should not send any
                information about yourself to us through the Service. Additionally, if you are
                located in the EU, EEA, United Kingdom or Switzerland you may use our Service only
                if you are over the age at which you can provide valid consent to data processing
                under the laws of your country.
              </p>
              <p className="text-body color-black">
                In the event we become aware that we have collected personal information from a
                child, we will dispose of that information in accordance with Children's Online
                Privacy Protection Act and other applicable laws and regulations.
              </p>
              <p className="heading-sm color-black">
                <strong>Promotional Communications</strong>
              </p>
              <p className="text-body color-black">
                If you would like to opt-out of receiving promotional emails or text messages, you
                can do by using the unsubscribe link in any marketing email, or by replying “STOP”
                to any marketing text message you receive. You may also opt-out by sending an e-mail
                to privacy@tryinteract.com that contains the email address or phone number you wish
                to opt-out of receiving promotional communications for. If you opt out, we may still
                send you non-promotional emails, such as those about your account or our ongoing
                business relations.
              </p>

              <p
                id="your-california-privacy-rights"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                7. Your California Privacy Rights
              </p>
              <p className="text-body color-black">
                The California Consumer Privacy Act or "CCPA" (California Civil Code Section
                1798.100 et seq.) affords consumers residing in California certain rights with
                respect to their personal information. If you are a California resident, this
                section applies to you if you are a Client or Respondent with a direct relationship
                with us by responding to one of Interact’s own Quizzes. This section of the Privacy
                Policy applies to information collected online and offline through, or in connection
                with, our Services.
              </p>
              <p className="heading-sm color-black">
                <em>California Consumer Privacy Act</em>
              </p>
              <p className="text-body color-black">
                In the preceding 12 months, we may have collected the categories of personal
                information set forth in the table below. For examples of the precise data points we
                collect and the categories of sources of such collection, please see{' '}
                <AnchorLink
                  to="/privacy/#collection-of-information"
                  title="Collection of Information"
                  className="color-pink"
                />{' '}
                above. We collect personal information for the business and commercial purposes
                described in{' '}
                <AnchorLink
                  to="/privacy/#use-of-information"
                  title="Use of Information"
                  className="color-pink"
                />{' '}
                above. In the preceding 12 months, we may have disclosed the following categories of
                personal information for business purposes to the categories of recipients also
                listed in the table below.
              </p>
              <table className="table table-bordered text-body color-black">
                <thead style={{ textAlign: `left` }}>
                  <tr>
                    <th>
                      <em>Categories of Personal Information</em>
                    </th>
                    <th>
                      <em>
                        Categories of Vendors to which Personal Information Is Disclosed for a
                        Business Purpose
                      </em>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Identifiers</td>
                    <td>
                      <ul>
                        <li>
                          Vendors and service providers such as payment processing, web hosting,
                          information technology, customer service, cloud service, data analytics,
                          social networks, marketing and advertising.
                        </li>
                        <li>
                          Law enforcement, government agencies, and for legal compliance, when
                          necessary
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Commercial Information</td>
                    <td>
                      <ul>
                        <li>
                          Vendors and service providers such as payment processing, web hosting,
                          information technology, cloud service, customer service, data analytics,
                          social networks, marketing and advertising law enforcement, government
                          agencies, and for legal compliance, when necessary
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Employment and Education Information</td>
                    <td>
                      <ul>
                        <li>
                          Vendors and service providers, such as human resources, information
                          technology, and cloud service.
                        </li>
                        <li>
                          Law enforcement, government agencies, and for legal compliance, when
                          necessary
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Personal information described in California Civil Code Section 1798.80(e)
                    </td>
                    <td>
                      <ul>
                        <li>
                          Vendors and processors, such as payment processing, information
                          technology, cloud service, customer service, and data analytics.
                        </li>
                        <li>
                          Law enforcement, government agencies, and for legal compliance, when
                          necessary
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Internet or Other Electronic Network Activity</td>
                    <td>
                      <ul>
                        <li>
                          Vendors and service providers such as payment processing, web hosting,
                          information technology, customer service, cloud service, data analytics,
                          social networks, and marketing and advertising
                        </li>
                        <li>
                          Law enforcement, government agencies, and for legal compliance, when
                          necessary
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Geolocation Data</td>
                    <td>
                      <ul>
                        <li>
                          Vendors and service providers such as payment processing, web hosting,
                          information technology, cloud service, customer service, data analytics,
                          social networks, marketing and advertising
                        </li>
                        <li>
                          Law enforcement, government agencies, and for legal compliance, when
                          necessary
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Inferences</td>
                    <td>
                      <ul>
                        <li>
                          Vendors and service providers such as information technology, data
                          analytics, marketing and advertising
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="text-body color-black">
                Interact does not sell your personal information for monetary or other valuable
                consideration. However, as explained above, we do allow our advertising partners to
                use cookies and other technologies to collect personal information about you in
                order to show ads that are targeted to your interests. In order to opt out of having
                your personal information used for targeted advertising purposes, please see{' '}
                <AnchorLink
                  to="/privacy/#advertising-and-analytics"
                  title="Advertising and Analytics"
                  className="color-pink"
                />{' '}
                Provided by Others above.
              </p>
              <p className="text-body color-black">
                We do not knowingly or intentionally sell personal information about consumers under
                the age of 16.
              </p>
              <p className="text-body color-black">
                Subject to certain limitations, you have the right to (1) request to know more about
                the categories and specific pieces of personal information we collect, use, and
                disclose, (2) request deletion of your personal information, (3) opt out of any
                “sales” of your personal information that may be occurring, and (4) not to be
                discriminated against for exercising these rights.
              </p>
              <p className="text-body color-black">
                The rights to know and delete are subject to our being able to reasonably verify
                your identity and authority to make these requests. In order to verify your identity
                when you submit a request, we will ask you to provide two (2) or three (3) pieces of
                personal information to confirm in our records. For example, if you purchased an
                Interact plan through our Site, you will be asked to provide at least your name and
                details of your last purchase. You may make these requests by emailing us at
                privacy@tryinteract.com. For requests to delete, you may also exercise your right by
                logging in to your Interact account by visiting{' '}
                <a href="https://dashboard.tryinteract.com/login">
                  https://dashboard.tryinteract.com/login
                </a>{' '}
                and navigating to “Privacy” located under “Account Settings” section of the user
                dashboard or by contacting us at The Quiz Collective, Inc., 2443 Fillmore St
                #380-14013, San Francisco, CA 94115.
              </p>
              <p className="text-body color-black">
                You may also authorize someone to exercise the above rights on your behalf. In order
                to do so, we require proof that you gave the agent signed permission to submit the
                request on your behalf. Once we have a copy of the valid signed authorization, your
                designated representative/authorized agent will be able to exercise these rights
                with respect to the account(s) listed on such authorization for the life of the
                account, unless there is a specified term or expiration date on the authorization
                form. If we receive your request from an authorized agent, we may ask for evidence
                that you have provided such agent with power of attorney or equivalent document that
                the agent otherwise has valid written authority to submit requests to exercise
                rights on your behalf. If you are an authorized agent seeking to make a request,
                please contact us at The Quiz Collective, Inc., 2443 Fillmore St #380-14013, San
                Francisco, CA 94115.{' '}
              </p>

              <p
                id="additional-disclosures-for-individuals-in-europe"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                8. Additional Disclosures for Individuals in Europe
              </p>
              <p className="text-body color-black">
                If you are located in the European Union (“EU”), the United Kingdom (“UK”) or
                Switzerland, and have a direct relationship with Interact, you have certain rights
                and protections under the law regarding the processing of your personal data by
                Interact, and this section of our Privacy Policy applies to you.
              </p>
              <p className="text-body color-black">
                We have included a couple of additional definitions that apply to individuals in the
                EU, UK and Switzerland:
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>
                  <strong>Data Controller.</strong> A “controller” is the legal entity which, alone
                  or jointly with others, determines the purposes and means of the processing of
                  personal information. Interact is the data controller for all data described by
                  this policy with the following exception: Information collected when individuals
                  participate in a quiz, poll, or promotion offered in connection with our Service
                  is controlled by the Client that created the poll, quiz, or promotion.
                </li>
                <li>
                  <strong>Data Processor.</strong> A “processor” is a legal entity or other body
                  which processes information collected from Respondents on behalf of the
                  controller, as defined above. Interact is a data processor for information
                  collected at the direction of Interact Clients using our Service.
                </li>
              </ul>
              <p className="heading-sm color-black">
                <strong>Transfer of Information from the EU, UK, or Switzerland</strong>
              </p>
              <p className="text-body color-black">
                Interact complies with the EU-U.S. Data Privacy Framework program (EU-U.S. DPF), the
                UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework program
                (Swiss-U.S. DPF) as set forth by the U.S. Department of Commerce. Interact has
                certified to the U.S. Department of Commerce that it adheres to the EU-U.S. Data
                Privacy Framework Principles (EU-U.S. DPF Principles) with regard to the processing
                of personal data received from the European Union in reliance on the EU-U.S. DPF and
                from the United Kingdom (and Gibraltar) in reliance on the UK Extension to the
                EU-U.S. DPF. Interact has certified to the U.S. Department of Commerce that it
                adheres to the Swiss-U.S. Data Privacy Framework program Principles (Swiss-U.S. DPF
                Principles) with regard to the processing of personal data received from Switzerland
                in reliance on the Swiss-U.S. DPF. If there is any conflict between the terms in
                this privacy policy and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF
                Principles, the Principles shall govern. To learn more about the Data Privacy
                Framework (DPF) program, and to view our certification, please visit{' '}
                <a
                  href="https://www.dataprivacyframework.gov/"
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  https://www.dataprivacyframework.gov/
                </a>
                .
              </p>
              <p className="text-body color-black">
                In compliance with the EU-US Data Privacy Framework Principles, Interact commits to
                resolve complaints about your privacy and our collection or use of your personal
                information transferred to the United States pursuant to the DPF Principles.
                European Union, Swiss and United Kingdom individuals with DPF inquiries or
                complaints should first contact Interact using the information in the{' '}
                <AnchorLink to="/privacy/#contact-us" title="Contact Us" className="color-pink" />{' '}
                section below.
              </p>
              <p className="text-body color-black">
                Interact has further committed to refer unresolved privacy complaints under the DPF
                Principles to an independent dispute resolution mechanism, Data Privacy Framework
                Services, operated by BBB National Programs. If you do not receive timely
                acknowledgment of your complaint, or if your complaint is not satisfactorily
                addressed, please visit{' '}
                <a
                  href="https://bbbprograms.org/programs/all-programs/dpf-consumers/ProcessForConsumers"
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  https://bbbprograms.org/programs/all-programs/dpf-consumers/ProcessForConsumers
                </a>{' '}
                for more information and to file a complaint. This service is provided free of
                charge to you.
              </p>
              <p className="text-body color-black">
                If your DPF complaint cannot be resolved through the above channels, under certain
                conditions, you may invoke binding arbitration for some residual claims not resolved
                by other redress mechanisms. See{' '}
                <a
                  href="https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf"
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf
                </a>
              </p>
              <p className="text-body color-black">
                Please note that Interact does not sell, rent or use personal data for reasons other
                than that for which it was originally collected. If any of those practices should
                change in the future, we will update this policy appropriately and provide
                individuals with opt-out or opt-in choice prior to the change being effective.
              </p>
              <p className="heading-sm color-black">
                <strong>Legal Basis for Processing</strong>
              </p>
              <p className="text-body color-black">
                When we process your personal data, we will do so in reliance on the following
                lawful bases:
              </p>
              <ul className="text-body color-black" style={{ margin: `1.5rem 0` }}>
                <li>
                  To perform our responsibilities under our contract with you (e.g., processing
                  payments for and providing the services you have ordered).
                </li>
                <li>
                  When we have a legitimate interest in processing your personal data to operate our
                  business correctly and efficiently or protect our interests (e.g., to provide,
                  maintain and improve our services, conduct data analytics, and communicate with
                  you).
                </li>
                <li>
                  When we find such processing is necessary to comply with a legal obligation to
                  which we are subject (e.g., to maintain a record of your consents and track those
                  who have opted-out of marketing communications).
                </li>
                <li>
                  When we have your consent to do so (e.g., when you agree to receive marketing
                  communications from us). When consent is the legal basis for our processing your
                  personal data, you may withdraw such consent at any time by emailing us at
                  privacy@tryinteract.com.
                </li>
              </ul>
              <p className="heading-sm color-black">
                <strong>Data Retention</strong>
              </p>
              <p className="text-body color-black">
                We will retain your personal information while you have a relationship with
                Interact, which may include (but is not limited to): registering for an account,
                subscribing to an Interact newsletter or otherwise having opted in to receiving
                marketing communications from us (without thereafter having unsubscribed or opted
                out); logging in to our Service; ordering a product from us; or creating, editing,
                or otherwise leaving active a Quiz on our Service.
              </p>
              <p className="text-body color-black">
                We maintain personal data for as long as is necessary for the purpose(s) for which
                we originally collected it and for other legitimate business purposes, including to
                meet our legal, regulatory, or other compliance obligations. You may request that we
                delete your personal data at any time by emailing us at privacy@tryinteract.com.
                Except as required under applicable law, your request will be honored within 30 days
                of receipt.
              </p>
              <p className="text-body color-black">
                In addition, Clients have the ability via the Service to delete Respondent
                Information for any specific Respondent at any time, using controls available in our
                Service.
              </p>
              <p className="text-body color-black">
                Notwithstanding the above, Interact will retain your information indefinitely if it
                believes in good faith that it has a legal obligation to do so.
              </p>
              <p className="heading-sm color-black">
                <strong>Data Subject Requests</strong>
              </p>
              <p className="text-body color-black">
                You have the right to request access to the personal data we hold about you and to
                ask that your personal data be corrected, erased, or transferred. You may also have
                the right to object to, or request that we restrict, certain processing. If you
                would like to exercise any of these rights, see instructions below.
              </p>
              <p className="text-body color-black">
                With respect to our Clients and individuals with whom we have a direct business
                relationship, individuals wishing to exercise these rights can (1) email
                privacy@tryinteract.com or, (2) if you have an account, login to their Interact
                account by visiting{' '}
                <a href="https://dashboard.tryinteract.com/login">
                  https://dashboard.tryinteract.com/login
                </a>{' '}
                and navigate to “Download Personal Data” located under “Account Settings” section of
                the user dashboard. If you are a Respondent and Interact processes your personal
                data on behalf of an Interact Client, please refer your request to our Client to
                handle. We provide Clients with a "Portability" function, which allows Clients to
                download all Respondent Information, including personal data, specific to that
                Client’s Quizzes.
              </p>
              <p className="text-body color-black">
                If you would like to remove your name and address from promotional lists (including
                any personal information gathered by our service providers) and place your name on
                our “do not contact” list, contact us at privacy@tryinteract.com and request that
                you be placed on our “do not contact” list. Please note that because names may be
                similar, you must include in your request all associated email addresses and phone
                numbers (if any) that you wish to be removed in the body of the email. We reserve
                the right to contact you for administrative purposes to request more information in
                order to assist us in deleting your account or information about you. For instance,
                if you send us an email using an email address that is not in our system, we may
                contact you to ask you for the email you used when you created your account. We may
                also send you a confirmation email, once we have deleted your information. We will
                make commercially reasonable efforts to delete your information within 30 days from
                our active files, provided, however, that we may retain—for legal compliance
                purposes only—your request and associated email in a hashed format so that we do not
                inadvertently restore your information to our database. Please note that requests to
                update your personal information may take up to five days. You may also request that
                we stop processing your information without deleting it, and we will comply within
                five days of receipt of such a request.
              </p>
              <p className="heading-sm color-black">
                <strong>Federal Trade Commission Enforcement</strong>
              </p>
              <p className="text-body color-black">
                Interact is subject to the investigatory and enforcement powers of the Federal Trade
                Commission (FTC) with regard to our compliance with the DPF. The FTC has
                jurisdiction to take enforcement action against organizations that fail to comply
                with their commitments under the DPF.
              </p>
              <p className="text-body color-black">
                If you have any questions or concerns about our handling of your personal data under
                the DPF, please contact us at privacy@tryinteract.com. If we are unable to
                satisfactorily resolve your concern, you can learn more about the DPF and how to
                file a complaint at{' '}
                <a
                  href="https://www.dataprivacyframework.gov/"
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  https://www.dataprivacyframework.gov/
                </a>
                .
              </p>
              <p className="heading-sm color-black">
                <strong>Liability in Cases of Onward Transfers to Third Parties</strong>
              </p>
              <p className="text-body color-black">
                In cases where we transfer personal data to third-party service providers or
                partners, we remain responsible for ensuring that these third parties process the
                personal data in compliance with applicable privacy laws. Our organization will take
                reasonable and appropriate steps to ensure that the third-party recipients process
                the personal data in accordance with our privacy policies and any applicable legal
                requirements.
              </p>
              <p className="text-body color-black">
                If you have any questions or concerns about our privacy practices or your personal
                data, please contact us at privacy@tryinteract.com.
              </p>

              <p
                id="contact-us"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                <strong>9. Contact Us</strong>
              </p>
              <p className="text-body color-black">
                If you have any questions about this Privacy Policy, please contact us at:
              </p>
              <p className="text-body color-black">
                The Quiz Collective, Inc.
                <br />
                2443 Fillmore St #380-14013
                <br />
                San Francisco, CA 94115
                <br />
                privacy@tryinteract.com
              </p>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ padding: `5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p
                className="heading-sm color-white text-center"
                style={{ marginTop: '0', marginBottom: '2rem' }}>
                Use our AI quiz maker to create a beautiful quiz for your brand in a minute.
              </p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">
                Make a quiz - for free
              </Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default PrivacyPage;
